import 'assets/styles/compliance.scss';

import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useStore } from 'hooks';

const BusinessDetail = () => {
  const [activeBusinessId, setActiveBusinessId] = useState('');
  const [businessData, setBusinessData] = useState();
  const [businessFunctionNext, setBusinessFunctionNext] = useState('');
  const [businessFunctionData, setBusinessFunctionData] = useState();
  const [businessFunctionCount, setBusinessFunctionCount] = useState(0);
  const [businessFunctionDisplayCount, setBusinessFunctionDisplayCount] = useState(0);
  const [businessFunctionsLoading, setBusinessFunctionsLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const store = useStore();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const getBusinessDetail = async (activeBusinessId) => {
    try {
      setIsLoading(true);
      const { data } = await store.api.getBusiness(activeBusinessId);
      setBusinessData(data);
    } catch (error) {
      if (error.response && error?.response?.status === 403) {
        enqueueSnackbar('You do not have permission to view this data', {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('An error occurred, please contact support', {
          variant: 'error',
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getBusinessFunctionList = async (activeBusinessId) => {
    try {
      const { data } = await store.api.getBusinessFunctionsForBusiness(activeBusinessId);
      setBusinessFunctionData(data.results);
      setBusinessFunctionNext(data.next);
      setBusinessFunctionCount(data.count);
      data.count > 10
        ? setBusinessFunctionDisplayCount(10)
        : setBusinessFunctionDisplayCount(data.count);
    } catch (error) {
      if (error.response && error?.response?.status === 403) {
        enqueueSnackbar('You do not have permission to view this data', {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('An error occurred, please contact support', {
          variant: 'error',
        });
      }
    }
  };

  const getNextBusinessFunctions = async () => {
    try {
      setBusinessFunctionsLoading(true);
      const { data } = await store.api.getNextDataWithURL(businessFunctionNext);
      let addNewDomains = businessFunctionData;
      setBusinessFunctionNext(data.next);
      setBusinessFunctionData(undefined);
      const arrayLength = data.results.length;
      data.results.map((process, i) => {
        addNewDomains.push(process);
        if (arrayLength == i + 1) {
          setBusinessFunctionData(addNewDomains);
          setBusinessFunctionDisplayCount(businessFunctionDisplayCount + arrayLength);
        }
      });
    } catch (error) {
      setBusinessFunctionsLoading(false);
      console.log(error);
      if (error.response && error?.response?.status === 403) {
        enqueueSnackbar('You do not have permission to view this data', {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('An error occurred, please contact support', {
          variant: 'error',
        });
      }
    } finally {
      setBusinessFunctionsLoading(false);
    }
  };

  useEffect(() => {
    const params = window.location.href;
    var url = new URL(params);
    var activeBusinessId = url.searchParams.get('business');
    setActiveBusinessId(activeBusinessId);
    getBusinessDetail(activeBusinessId);
    getBusinessFunctionList(activeBusinessId);
  }, []);

  const navigateToBusinessFunction = (activeBusinessId, companyBusinessFunctionId) => {
    const path =
      '/business-function' +
      '?business=' +
      activeBusinessId +
      '&business_function=' +
      companyBusinessFunctionId;
    history.push(path);
  };

  return (
    <section className={'compliance-container'}>
      <div className="back-nav" onClick={() => history.push('/businesses')}>
        Back to Businesses
      </div>
      <div className="header-icon"></div>
      {businessData === undefined && (
        <div className="loading-icon-container">
          <div className="loading-icon"></div>
        </div>
      )}
      <h4>Business - {businessData !== undefined && businessData.business.name}</h4>

      <ul className="categories-list">
        <li key="count" style={{ backgroundColor: '#f4f5f7' }}>
          <span>
            Displaying {businessFunctionDisplayCount} of {businessFunctionCount} Business Functions
          </span>
        </li>
        {businessFunctionData !== undefined &&
          businessFunctionData.map((companyBusinessFunction, i) => {
            const companyBusinessFunctionId = companyBusinessFunction.id;

            return (
              <li
                key={i}
                onClick={() =>
                  navigateToBusinessFunction(activeBusinessId, companyBusinessFunctionId)
                }
              >
                <span>{companyBusinessFunctionId}</span>
                <span className="title-spacing">
                  {companyBusinessFunction.business_function.name}
                </span>
                <div className="status-text">
                  <span className="last-updated">
                    LAST UPDATED BY {companyBusinessFunction.last_updated_by.username} AT{' '}
                    {companyBusinessFunction.updated_on}
                  </span>
                  {/* <span className="owner">Owner: {companyBusinessFunction.owner.first_name}</span> */}
                </div>
                <span
                  className={`applicable-status ${
                    companyBusinessFunction.applicable === 'Applicable' ? '' : 'not-applicable'
                  }`}
                >
                  {companyBusinessFunction.applicable}
                </span>
              </li>
            );
          })}
        {businessFunctionCount > 10 && (
          <li className="get-next" key="next" onClick={() => getNextBusinessFunctions()}>
            <span>{businessFunctionsLoading ? 'Loading...' : 'Load 10 more'}</span>
          </li>
        )}
      </ul>
    </section>
  );
};

BusinessDetail.defaultProps = {};

export default BusinessDetail;
