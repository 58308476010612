import 'assets/styles/compliance.scss';

import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useStore } from 'hooks';

const MicroRegulatoryProcessesList = (props) => {
  const [ActiveRegulationId, setActiveRegulationId] = useState('');
  const [activeBusinessFunctionId, setActiveBusinessFunctionId] = useState('');
  const [activeBusinessProcessId, setActiveBusinessProcessId] = useState('');
  const [businessProcessData, setBusinessProcessData] = useState();
  const [microRegulatoryProcessNext, setMicroRegulatoryProcessNext] = useState('');
  const [microRegulatoryProcessData, setMicroRegulatoryProcessData] = useState();
  const [microRegulatoryProcessCount, setMicroRegulatoryProcessCount] = useState(0);
  const [microRegulatoryProcessDisplayCount, setMicroRegulatoryProcessDisplayCount] = useState(0);
  const [microRegulatoryProcessLoading, setMicroRegulatoryProcessLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const store = useStore();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const getMicroRegulatoryProcessList = async (regulationId) => {
    try {
      const { data } = await store.api.getMicroProcessByRegulation(regulationId);
      setMicroRegulatoryProcessData(data.results);
      setMicroRegulatoryProcessNext(data.next);
      setMicroRegulatoryProcessCount(data.count);
      data.count > 10
        ? setMicroRegulatoryProcessDisplayCount(10)
        : setMicroRegulatoryProcessDisplayCount(data.count);
    } catch (error) {
      if (error.response && error?.response?.status === 403) {
        enqueueSnackbar('You do not have permission to view this data', {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('An error occurred, please contact support', {
          variant: 'error',
        });
      }
    }
  };

  const getNextMicroRegulatoryProcesses = async () => {
    try {
      setMicroRegulatoryProcessLoading(true);
      const { data } = await store.api.getNextDataWithURL(microRegulatoryProcessNext);
      let newMicroRegulatoryProcesses = microRegulatoryProcessData;
      setMicroRegulatoryProcessNext(data.next);
      setMicroRegulatoryProcessData(undefined);
      const arrayLength = data.results.length;
      data.results.map((process, i) => {
        newMicroRegulatoryProcesses.push(process);
        if (arrayLength == i + 1) {
          setMicroRegulatoryProcessData(newMicroRegulatoryProcesses);
          setMicroRegulatoryProcessDisplayCount(microRegulatoryProcessDisplayCount + arrayLength);
        }
      });
    } catch (error) {
      setMicroRegulatoryProcessLoading(false);
      console.log(error);
      if (error.response && error?.response?.status === 403) {
        enqueueSnackbar('You do not have permission to view this data', {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('An error occurred, please contact support', {
          variant: 'error',
        });
      }
    } finally {
      setMicroRegulatoryProcessLoading(false);
    }
  };

  useEffect(() => {
    const params = window.location.href;
    let url = new URL(params);
    let regulationId = url.searchParams.get('regulation');
    setActiveRegulationId(regulationId);
    getMicroRegulatoryProcessList(regulationId);
  }, []);

  const navigateToMicroRegulatoryProcess = (microProcessId) => {
    const path = 'micro-regulatory-process-detail?micro_process='+microProcessId;
    history.push(path);
  };

  return (
    <section className={'compliance-container'}>
      <div className="back-nav" onClick={() => history.push('/dashboard')}>
        Home
      </div>
      <div className="header-icon"></div>
      <h4>
        Compliance Micro-Process Library
      </h4>

      <ul className="categories-list">
        <li key="count" style={{ backgroundColor: '#f4f5f7' }}>
          <span>
            Displaying {microRegulatoryProcessDisplayCount} of {microRegulatoryProcessCount} Micro
            Regulatory Processes
          </span>
        </li>
        {microRegulatoryProcessData !== undefined &&
          microRegulatoryProcessData.map((microProcess, i) => {
            const microProcessId = microProcess.id;

            return (
              <li key={i} onClick={() => navigateToMicroRegulatoryProcess(microProcessId)}>
                <span>{microProcessId}</span>
                <span className="title-spacing">{microProcess.micro_regulatory_process.name}</span>
                <div className="status-text">
                  <span className="last-updated">Last Updated At {microProcess.last_updated}</span>
                  <span className="owner"> Owner: {microProcess.owner.first_name}</span>
                </div>
                {microProcess.validation_status == 'Passed' ? (
                  <div className="status green-status"></div>
                ) : (
                  <div className="status red-status"></div>
                )}
              </li>
            );
          })}
        {microRegulatoryProcessCount > 10 && (
          <li className="get-next" key="next" onClick={() => getNextMicroRegulatoryProcesses()}>
            <span>{microRegulatoryProcessLoading ? 'Loading...' : 'Load 10 more'}</span>
          </li>
        )}
      </ul>
    </section>
  );
};

MicroRegulatoryProcessesList.defaultProps = {};

export default MicroRegulatoryProcessesList;