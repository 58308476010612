import 'assets/styles/compliance.scss';

import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useStore } from 'hooks';

const Businesses = () => {
  const [businessData, setBusinessData] = useState();
  const [businessesNext, setBusinessesNext] = useState('');
  const [businessesCount, setBusinessesCount] = useState(0);
  const [businessesDisplayCount, setBusinessesDisplayCount] = useState(0);
  const [businessesLoading, setBusinessesLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const store = useStore();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const getBusinesses = async () => {
    try {
      setIsLoading(true);
      const { data } = await store.api.getBusinesses();
      setBusinessData(data.results);
      setBusinessesNext(data.next);
      setBusinessesCount(data.count);
      data.count > 10 ? setBusinessesDisplayCount(10) : setBusinessesDisplayCount(data.count);
    } catch (error) {
      if (error.response && error?.response?.status === 403) {
        enqueueSnackbar('You do not have permission to view this data', {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('An error occurred, please contact support', {
          variant: 'error',
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getNextBusinesses = async () => {
    try {
      setBusinessesLoading(true);
      const { data } = await store.api.getNextDataWithURL(businessesNext);
      let addNewDomains = businessData;
      setBusinessesNext(data.next);
      setBusinessData(undefined);
      const arrayLength = data.results.length;
      data.results.map((process, i) => {
        addNewDomains.push(process);
        if (arrayLength == i + 1) {
          setBusinessData(addNewDomains);
          setBusinessesLoading(false);
          setBusinessesDisplayCount(businessesDisplayCount + arrayLength);
        }
      });
    } catch (error) {
      setBusinessesLoading(false);
      if (error.response && error?.response?.status === 403) {
        enqueueSnackbar('You do not have permission to view this data', {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('An error occurred, please contact support', {
          variant: 'error',
        });
      }
    } finally {
      setBusinessesLoading(false);
    }
  };

  useEffect(() => {
    getBusinesses();
  }, []);

  const navigateToBusiness = (businessId) => {
    const path = '/business?business=' + businessId;
    history.push(path);
  };

  return (
    <section className={'compliance-container'}>
      <div className="back-nav" onClick={() => history.push('/dashboard')}>
        Home
      </div>
      <div className="header-icon"></div>
      <h4>Businesses</h4>

      <ul className="categories-list">
        <li key="count" style={{ backgroundColor: '#f4f5f7' }}>
          <span>
            Displaying {businessesDisplayCount} of {businessesCount} Businesses
          </span>
        </li>

        {businessData !== undefined &&
          businessData.map((companyBusiness, i) => {
            const businessId = companyBusiness.id;
            return (
              <li key={i} onClick={() => navigateToBusiness(businessId)}>
                <span>{businessId}</span>
                <span className="title-spacing">{companyBusiness.business.name}</span>
                <div className="status-text">
                  <span className="last-updated">
                    LAST UPDATED BY {companyBusiness?.last_updated_by.username} AT{' '}
                    {companyBusiness?.updated_on}
                  </span>
                  {/* <span className="owner">
                    <b>{companyBusiness?.business?.owner?.username}</b> Owner
                  </span> */}
                </div>
                <span
                  className={`applicable-status ${
                    companyBusiness.applicable === 'Applicable' ? '' : 'not-applicable'
                  }`}
                >
                  {companyBusiness.applicable}
                </span>
              </li>
            );
          })}
        {businessesDisplayCount > 10 && (
          <li className="get-next" key="next" onClick={() => getNextBusinesses()}>
            <span>{businessesLoading ? 'Loading...' : 'Load 10 more'}</span>
          </li>
        )}
      </ul>
    </section>
  );
};

Businesses.defaultProps = {};

export default Businesses;
