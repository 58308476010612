// TODO - Not yet implemented - Start
export const AUTH_CONFIRM_SIGN_UP = '/account/auth/confirm-sign-up/';
export const AUTH_RESEND_CONFIRMATION_TOKEN = '/account/auth/resend-confirmation-code/';
export const AUTH_RESPOND_TO_CHALLENGE = '/account/auth/respond-to-challenge/';
export const FORGOT_PASSWORD = '/account/auth/forgot-password/';
export const CONFIRM_FORGOT_PASSWORD = '/account/auth/confirm-forgot-password/';
// TODO - Not yet implemented - End

export const AUTH_LOGIN = '/login/';
export const AUTH_REFRESH_TOKEN = '/refresh-token/';
export const AUTH_LOGOUT = '/logout/';

// Company
export const ACTIVE_USER = '/company/active-user/';
export const COMPLIANCE_FRAMEWORK = '/compliance/regulatory-compliance-frameworks/:id/';
export const OPERATIONAL_CONTROL_FRAMEWORK = '/compliance/operational-control-frameworks/:id/';
export const BUSINESSES = '/company/company-businesses/';
export const BUSINESS = '/company/company-businesses/:id/';
export const BUSINESS_FUNCTIONS = '/company/company-business-functions/';
export const BUSINESS_FUNCTION = '/company/company-business-functions/:id/';
export const BUSINESS_PROCESSES = '/company/company-business-processes/';
export const BUSINESS_PROCESS = '/company/company-business-processes/:id/';
export const  DOCUMENT_REGULATIONS = '/compliance/company-regulation-document/'

// Compliance
export const MICRO_PROCESSES = '/compliance/company-micro-regulatory-processes/';
export const MICRO_PROCESSES_REVIEWED = '/compliance/company-micro-regulatory-processes-reviewed/';
export const MICRO_PROCESSES_SUGGESTED = '/compliance/company-micro-regulatory-processes-suggested/';
export const MICRO_PROCESSES_READ = '/compliance/company-micro-regulatory-processes-read/';
export const MICRO_PROCESSES_FAILED = '/compliance/company-micro-regulatory-processes-failed/';
export const MICRO_PROCESS = '/compliance/company-micro-regulatory-processes/:id/';
export const MICRO_PROCESS_BY_REGULATION = '/compliance/company-micro-regulatory-processes-by-regulation/:id/'
export const MICRO_PROCESS_VALIDATION_LOGS =
  '/compliance/micro-regulatory-process-validation-logs/';
export const MICRO_PROCESS_VALIDATION_LOG =
  '/compliance/micro-regulatory-process-validation-logs/:id/';
export const COMPLIANCE_DOCUMENT_VALIDATION_LOGS =
  '/compliance/compliance-document-validation-logs/';
export const COMPLIANCE_DOCUMENT_VALIDATION_LOG =
  '/compliance/compliance-document-validation-logs/:id/';

export const RISKS_API_ENDPOINT = '/compliance/risks/';
export const RISK = '/compliance/risks/:id/';
export const RISK_PROCESS = '/compliance/risk-rules/:id/';
export const RISK_PROCESS_API_ENDPOINT = '/compliance/risk-rules/';

export const POLICIES_API_ENDPOINT = '/compliance/policies/';
export const POLICY = '/compliance/policies/:id/';
export const POLICY_PROCESS = '/compliance/policy-rules/:id/';
export const POLICY_PROCESS_API_ENDPOINT = '/compliance/policy-rules/';

export const PROCEDURES_API_ENDPOINT = '/compliance/procedures/';
export const PROCEDURE = '/compliance/procedures/:id/';
export const PROCEDURE_PROCESS = '/compliance/procedure-rules/:id/';
export const PROCEDURE_PROCESS_API_ENDPOINT = '/compliance/procedure-rules/';
export const FILE_S3_PROCESS_API_ENDPOINT = '/compliance/procedure-rules/files/:name';
export const FILE_S3_LIST_PROCESS_API_ENDPOINT = '/compliance/procedure-rules/files/dir/:id';
export const FILE_S3_LIST_ROOT_PROCESS_API_ENDPOINT = '/compliance/procedure-rules/files/dir/root/:id';
export const FILE_S3_SENT_PROCESS_API_ENDPOINT = '/compliance/procedure-rules/files/sent/';
export const FILE_S3_LINK_FILE = 'compliance/procedure-rules/files/link/';

export const EVIDENCES_API_ENDPOINT = '/compliance/evidences/';
export const EVIDENCE = '/compliance/evidence/:id/';
export const EVIDENCE_PROCESS = '/compliance/evidence-rules/:id/';
export const EVIDENCE_PROCESS_API_ENDPOINT = '/compliance/evidence-rules/';

export const CONTROLS_API_ENDPOINT = '/compliance/controls/';
export const CONTROL = '/compliance/control/:id/';
export const CONTROL_PROCESS = '/compliance/control-rules/:id/';
export const CONTROL_PROCESS_API_ENDPOINT = '/compliance/control-rules/';

export const MONITORINGS_API_ENDPOINT = '/compliance/monitorings/';
export const MONITORING = '/compliance/monitoring/:id/';
export const MONITORING_PROCESS = '/compliance/monitoring-rules/:id/';
export const MONITORING_PROCESS_API_ENDPOINT = '/compliance/monitoring-rules/';

export const PUBLIC_ENDPOINTS = [
  AUTH_LOGIN,
  AUTH_CONFIRM_SIGN_UP,
  FORGOT_PASSWORD,
  CONFIRM_FORGOT_PASSWORD,
  AUTH_RESEND_CONFIRMATION_TOKEN,
];
